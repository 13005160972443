//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      slideDelay: 10000,
      loading: true,
      currentSlide: null,
      ytApiTag: null,
      ytPlayer: null,
      lastYT: null,
      lastIndex: 0,
      videoPlayState: false,
      sliderTimeout: null,
    };
  },
  props: {
    media: {
      type: Array,
      default: () => [],
    },
    mobileMedia: {
      type: Array,
      default: () => [],
    },
    chatIcon: {
      type: Boolean,
      default: false,
    },
    classProp: {
      type: String,
      default: "",
    },
    homeMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    slideSet() {
      if (this.$isMobile) {
        return this.mobileMedia.map((item) => ({
          type: "IMG",
          url: item.url,
          link: item.link,
        }));
      } else {
        return this.media.map((item) => {
          if (item.type == "IMG") {
            return {
              type: "IMG",
              url: item.url,
              link: item.link,
            };
          } else {
            if (this.validateYoutubeURL(item.url)) {
              return {
                type: "YTV",
                url: this.validateYoutubeURL(item.url),
                link: null,
              };
            } else {
              return { type: "VID", url: item.url, link: null };
            }
          }
        });
      }
    },
  },
  mounted() {
    this.currentSlide = this.slideSet[0];
    //youtube api script
    this.initiateYTapi();
    this.$nextTick(() => {
      this.lastIndex = 0;
      //this.autoSlide();
    });
  },
  methods: {
    offLoading() {
      if (this.currentSlide.type == "VID") {
        let video = document.getElementById("videoPlayer");
        this.slideDelay = video.duration * 1000;
      } else {
        this.slideDelay = 10000;
      }
      setTimeout(() => {
        this.loading = false;
      }, 200);
    },
    playPausetoggle() {
      if (this.videoPlayState) {
        document.getElementById("videoPlayer").pause();
      } else {
        document.getElementById("videoPlayer").play();
      }
    },
    gotoSlide(index) {
      if (this.sliderTimeout != null) {
        clearTimeout(this.sliderTimeout);
      }
      this.loading = true;
      this.currentSlide = this.slideSet[index];

      if (this.currentSlide.type == "VID") {
        if (this.ytPlayer != null) {
          this.ytPlayer.pauseVideo();
        }
        if (document.getElementById("videoPlayer")) {
          document.getElementById("videoPlayer").pause();
        }
        setTimeout(() => {
          let video = document.getElementById("videoPlayer");
          this.slideDelay = video.duration * 1000;
          video.play();
        }, 100);
      } else if (this.currentSlide.type == "YTV") {
        setTimeout(() => {
          document.getElementById("videoPlayer").pause();
          if (this.ytPlayer == null) {
            this.initiateYTPlayer(this.currentSlide.url);
          } else {
            //document.getElementById("youtubePlayer").style.display = "initial";
            this.ytPlayer.loadVideoById(this.currentSlide.url);
          }
        }, 100);
      } else {
        document.getElementById("videoPlayer").pause();
        if (this.ytPlayer != null) {
          this.ytPlayer.pauseVideo();
        }
        // document.getElementById("youtubePlayer").style.display = "none";
      }
      this.lastIndex = index;
    },

    validateYoutubeURL(url) {
      var p =
        /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      var matches = url.match(p);
      if (matches) {
        return matches[1];
      }
      return false;
    },
    initiateYTapi() {
      if (this.ytApiTag == null) {
        this.ytApiTag = document.createElement("script");
        this.ytApiTag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(this.ytApiTag, firstScriptTag);
      }
    },
    initiateYTPlayer(vId) {
      this.ytPlayer = new YT.Player("youtubePlayer", {
        height: "390",
        width: "640",
        videoId: vId,
        events: {
          onReady: this.onPlayerReady,
          //'onStateChange': onPlayerStateChange
        },
      });
    },
    onPlayerReady(event) {
      this.lastYT = event.target;
      this.lastYT.playVideo();
      //this.lastVideo = index;
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
  watch: {
    slideSet(val) {
      if (val.length) {
        if (this.slideSet[0].type == "VID") {
          let video = document.getElementById("videoPlayer");
          video.play();
        } else if (this.slideSet[0].type == "YTV") {
          this.initiateYTPlayer(this.slideSet[0].url);
        }
      }
    },
    $isMobile() {
      this.$forceUpdate();
    },
    loading(val) {
      if (!val) {
        if (this.slideSet.length > 1) {
          clearTimeout(this.sliderTimeout);
          if (this.slideSet.length > this.lastIndex + 1) {
            this.sliderTimeout = setTimeout(() => {
              this.gotoSlide(this.lastIndex + 1);
            }, this.slideDelay);
          } else {
            this.sliderTimeout = setTimeout(() => {
              this.gotoSlide(0);
            }, this.slideDelay);
          }
        }
      }
    },
  },
};
