//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    visibility: {
      get() {
        return this.$store.state.videoPopupModal;
      },
      set(val) {
        this.$store.commit("SET_VIDEO_POPUP_MODAL", val);
      },
    },
    modalData() {
      return this.$store.state.videoPopupData;
    },
  },
};
