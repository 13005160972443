var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.compData.length)?_c('slick',{staticClass:"social-wall position-relative",attrs:{"options":_vm.sliderOptions}},_vm._l((_vm.compData),function(item,index){return _c('b-col',{key:index + 'social',staticClass:"social-wall-block",attrs:{"data-aos":"fade-down","data-aos-delay":(index % 6) * 100,"data-aos-duration":"1000","cols":"12","xl":_vm.gridPattern[index] * 3}},[_c('a',{attrs:{"href":item.link,"target":"_blank","rel":"socialwall"}},[_c('div',{staticClass:"social-wall-outer",class:("width-" + (_vm.gridPattern[index]))},[_c('div',{staticClass:"social-wall-inner prefix-effects",class:{
            'no-hover':
              item.description[_vm.$language] == '' ||
              item.description[_vm.$language] == null,
          }},[_c('div',{staticClass:"social-wall-img"},[_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],attrs:{"data-src":item.image_url,"alt":"Social Wall"}})]),_vm._v(" "),_c('div',{staticClass:"social-wall-content",class:{
              'no-bg':
                item.description[_vm.$language] == '' ||
                item.description[_vm.$language] == null,
            }},[_c('span',{staticClass:"social-wall-icon",class:("icon-" + (item.icon_type))}),_vm._v(" "),_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"short fixed-font-16 txt-w-l"},[_vm._v("\n                "+_vm._s(_vm._f("trimText")(item.description[_vm.$language],60))+"\n              ")]),_vm._v(" "),_c('span',{staticClass:"long fixed-font-16 txt-w-r"},[_vm._v("\n                "+_vm._s(_vm._f("trimText")(item.description[_vm.$language],160))+"\n              ")])])]),_vm._v(" "),_c('div',{staticClass:"social-wall-overlay"})])])])])}),1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }