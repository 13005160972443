//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {};
  },
  props: {
    data: Array,
    reqired: true,
  },
  methods: {
    popUpVideo(index) {
      if (this.validateYoutubeURL(this.data[index].video_url)) {
        this.$store.commit("SET_VIDEO_POPUP_DATA", {
          type: "YTV",
          url: this.validateYoutubeURL(this.data[index].video_url),
          title: this.data[index].header[this.$language],
        });
      } else {
        this.$store.commit("SET_VIDEO_POPUP_DATA", {
          type: "VID",
          url: this.data[index].video_url,
          title: this.data[index].header[this.$language],
        });
      }
      this.$store.commit("SET_VIDEO_POPUP_MODAL", true);
    },
    validateYoutubeURL(url) {
      var p =
        /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      var matches = url.match(p);
      if (matches) {
        return matches[1];
      }
      return false;
    },
  },
};
