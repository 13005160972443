//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import ourTeasRecommendations from "~/components/modal/our-teas-recommendations";
// import mainSlider from "~/components/main-slider";
// import VideoPopup from "~/components/modal/video-popup";
import infiniteSlider from "~/components/infinite-slider";
import MainSliderV2 from "~/components/main-slider-v2";
import VideoGrid from "~/components/video-grid";
import SocialWall from "~/components/social-wall";
import { KinesisContainer, KinesisElement } from "vue-kinesis";
import HomeBoxSlider from "~/components/home-box-slider";

export default {
  head: {
    meta: [
      {
        hid: "description",
        name: "description",
        content:
          "Buy pure Ceylon tea online exclusively from Dilmah. Enjoy free shipping on all your favourite teas. Buy Now. Shop Now. Highlights: Free Shipping Available, Customer Service Available.",
      },
      {
        property: "og:title",
        content:
          "Pure Ceylon Tea | Best Ceylon Tea Brand | Dilmah Tea Official Website",
      },
      {
        property: "og:description",
        content:
          "Buy pure Ceylon tea online exclusively from Dilmah. Enjoy free shipping on all your favourite teas. Buy Now. Shop Now. Highlights: Free Shipping Available, Customer Service Available.",
      },
      { property: "og:url", content: "https://www.dilmahtea.com/" },
      {
        property: "og:image",
        content: "https://www.dilmahtea.com/images/accessories-bg.png",
      },
    ],
  },
  components: {
    // ourTeasRecommendations,
    // mainSlider,
    // VideoPopup,
    infiniteSlider,
    MainSliderV2,
    VideoGrid,
    SocialWall,
    KinesisContainer,
    KinesisElement,
    HomeBoxSlider,
  },
  mounted() {
    this.$store.dispatch("gethomePage");
    // this.$store.dispatch("getImages", { page: 1, size: 20 });
    // this.$store.dispatch("getNews", { page: 1, size: 20 });
    this.$store.dispatch("getFeaturedStories", {
      type: "featured",
      page: 1,
      size: 10,
    });
    this.$store.dispatch("getFeaturedStories", {
      type: "tea_inspired",
      page: 1,
      size: 10,
    });
    this.$store.dispatch("getFeaturedStories", {
      type: "purpose",
      page: 1,
      size: 10,
    });
    this.$store.dispatch("getProductsAndInfutions", {
      page: 1,
      size: 10,
    });
  },
  computed: {
    pageData() {
      if (this.$store.state.home != null) {
        return this.$store.state.home.page;
      }
    },
    boxData() {
      if (this.$store.state.home != null) {
        return this.$store.state.home.page.contents.map((item) => ({
          heading: item.header.text_en,
          image: item.image_url,
          text: item.content.text_en,
          has_link: item.has_link,
          link: item.link,
          link_text: item.link_text,
        }));
      }
    },
    sliderMedia() {
      if (this.$store.state.home != null) {
        return this.$store.state.home.page.sliders.map((item) => ({
          type: item.slider_has_video ? "VID" : "IMG",
          url: item.slider_has_video ? item.video_url : item.image_url,
          link: item.link,
        }));
      }
    },
    sliderMediaMobile() {
      if (this.$store.state.home != null) {
        return this.$store.state.home.page.mobile_sliders.map((item) => ({
          type: "IMG",
          url: item.image_url,
          link: item.link,
        }));
      }
    },
    featuredData() {
      return this.$store.state.featuredStories.featured.map((item) => ({
        image: item.thumbnail,
        text: item.name,
        hash: item.name.split(" ").join("_"),
        shortDescription: item.short_description,
        category:
          item.category == "press_articles"
            ? "media/dilmah-in-press"
            : "media/news",
      }));
    },
    teaInspiredData() {
      return this.$store.state.featuredStories.tea_inspired.map((item) => ({
        image: item.thumbnail,
        text: item.name,
        hash: item.name.split(" ").join("_"),
        shortDescription: item.short_description,
        category:
          item.category == "press_articles"
            ? "media/dilmah-in-press"
            : "media/news",
      }));
    },
    purposeData() {
      return this.$store.state.featuredStories.purpose.map((item) => ({
        image: item.thumbnail,
        text: item.name,
        hash: item.name.split(" ").join("_"),
        shortDescription: item.short_description,
        category:
          item.category == "press_articles"
            ? "media/dilmah-in-press"
            : "media/news",
      }));
    },
    productsAndInfusionsData() {
      return this.$store.state.productsAndInfutions.map((item) => ({
        image: item.product_images.length > 0 ? item.product_images[0] : null,
        text: item.product_name,
        shortDescription: "",
      }));
    },
  },
};
