//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      gridPattern: [2, 1, 1, 1, 1, 2],
      sliderOptions: {
        arrows: false,
        infinite: false,
        slidesToShow: 4,
        rows: 2,
        slidesPerRow: 3,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        nextArrow:
          '<span class="icon-caret-right slider-next slick-arrow-s1"></span>',
        prevArrow:
          '<span class="icon-caret-left slider-prev slick-arrow-s1" style="top:-50px"></span>',
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              rows: 1,
              slidesToShow: 1,
              slidesPerRow: 1,
              arrows: true,
            },
          },
        ],
      },
      fbPosts: [],
      instaPosts: [],
      tweets: [],
    };
  },
  created() {
    this.getInstaPosts();
    this.getFBposts();
    this.getTweets();
  },
  mounted() {
    this.$store.dispatch("getSocailWall");
    this.$nextTick(() => {});
  },
  computed: {
    compData() {
      return this.$store.state.socialWall;
    },
  },
  async fetch() {
    let accToken =
      "AAAAAAAAAAAAAAAAAAAAADJVZQEAAAAA%2BbZKpyy1P7rur6bb8KC77YA2syU%3DKSflKyV9t71zGZDCdk5pVJ5YLOIAMHQZFwqTG4EFcY8AlFZwtm";
    let userId = "374308949";
    this.$axios
      .$get(`https://api.twitter.com/2/users/${userId}/tweets`, {
        params: {
          "tweet.fields": "attachments,author_id,source,entities,geo",
        },
        headers: {
          Authorization: `Bearer ${accToken}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Authorization, X-Requested-With",
        },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    async getInstaPosts() {
      let accToken =
        "IGQVJVYmplOEhVS21Ea2loeVJwd19xa3NyWnhORldxYUFEell3TjZACVlJ2VXRXVFRhME5hOEZAPWXZArakE4SGFsXy1vblNLWjcxRGV2Rjhqanh2cFE3c2M5aEdpN2hPY1JKdmlKMWRBTVpKaVFJdERzUwZDZD";
      await this.$axios
        .$get(
          `https://graph.instagram.com/me/media?fields=id,caption,media_url,permalink,thumbnail_url,media_type,username,timestamp&access_token=${accToken}`
        )
        .then((res) => {
          this.instaPosts = res.data.map((item) => ({
            image:
              item.media_type != "VIDEO" ? item.media_url : item.thumbnail_url,
            url: item.permalink,
            text: item.caption,
            from: "instagram",
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getFBposts() {
      let accessToken =
        "EAAHydR7lfTgBACqZBTUHscXfUhgyWQXz8gja5UUHBZA8MTlM2ZA2XkSCZBscabbOSkKlp5l6Hl4JZAcAsUUaWdP0pVyBZBxT6T6o18yO5bxsm2vlwD7uB2UZBqa1KTDcuZAKQUqPCFuAIF3FfivVoPG0vUkqS7NZAvGoBhmwi9npIVeKfVZCrzTmzFnCGvb6pOfAwZD";
      let page = "gearUpStyling";
      await this.$axios
        .$get(`https://graph.facebook.com/v13.0/${page}/feed`, {
          params: {
            fields: "attachments{media,media_type,title,description,url}",
            access_token: accessToken,
          },
        })
        .then(async (res) => {
          console.log(res.data);
          let data = await res.data.map((item) => item.attachments.data[0]);
          setTimeout(() => {
            this.fbPosts = data.map((item) => ({
              image: item.media ? item.media.image.src : null,
              url: item.url,
              text: item.description ? item.description : item.title,
              from: "facebook",
            }));
          }, 10);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTweets() {},
  },
};
