import { render, staticRenderFns } from "./index.vue?vue&type=template&id=19c0fb08&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MainSliderV2: require('/app/components/main-slider-v2.vue').default,InfiniteSlider: require('/app/components/infinite-slider.vue').default,HomeBoxSlider: require('/app/components/home-box-slider.vue').default,VideoGrid: require('/app/components/video-grid.vue').default,VideoPopup: require('/app/components/modal/video-popup.vue').default})
