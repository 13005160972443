//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    emptySlides: {
      type: Number,
      default: 0,
    },
    slidesNo: {
      type: Number,
      default: false,
    },
    slideSize: {
      type: Array,
      default: () => ["260px", "320px"],
    },
    slides: {
      type: Array,
      default: () => [],
    },
    noHover: {
      type: Boolean,
      default: false,
    },
    halfHover: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    content_type: {
      type: String,
      default: "",
    },
    featuredMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sliderOptions: {
        arrows: true,
        slidesToShow: this.slidesNo,
        infinite: false,
        slidesToScroll: 1,
        slidesToShow: 5,
        variableWidth: true,
        adaptiveHeight: true,
        centerMode: false,
        dots: false,
        nextArrow:
          '<div class="infinite-next-button pr-sm-5 pr-0"><span class="icon-caret-right slider-next"></span></div>',
        prevArrow:
          '<div class="infinite-prev-button pl-5"><span class="icon-caret-left slider-prev"></span></div>',
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 540,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
    };
  },
  computed: {
    styleObject() {
      return {
        "--slide-width": this.slideSize[0],
        "--slide-height": this.slideSize[1],
      };
    },
    extraSlides() {
      return this.$isMobile ? this.emptySlides - 1 : this.emptySlides;
    },
  },
};
