//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      update: 0,
      teaLeaf: ["tree-leaf.png", "tree-leaf-1.png"],
      sliderOptions: {
        arrows: false,
        infinite: false,
        slidesToShow: 4,
        rows: 2,
        slidesPerRow: 2,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        nextArrow:
          '<span class="icon-arrow-right slider-next slick-arrow-s1"></span>',
        prevArrow:
          '<span class="icon-arrow-left slider-prev slick-arrow-s1"></span>',
        responsive: [
          {
            breakpoint: 767,
            settings: {
              rows: 1,
              slidesToShow: 1,
              slidesPerRow: 1,
              arrows: true,
            },
          },
        ],
      },
    };
  },

  props: {
    icon: {
      type: String,
      default: null,
    },
    leaves: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
